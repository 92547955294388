import { graphql, PageProps } from "gatsby";

import { lazy } from "react";
import { useInView } from "react-intersection-observer";
import { useStoryblok } from "@/base/lib/storyblok";
import MainLayout from "@/components/layout/Main";

import LazyIntersectionObserver from "../elements/LazyIntersectionObserver/LazyIntersectionObserver";
import DocumentReport from "../sections/DocumentReport";
import ScientificUpdateContent from "../sections/ScientificUpdateContent";
import ScientificUpdateHero from "../sections/ScientificUpdateHero";

type PageData = {
  header: StoryblokStory;
  footer: StoryblokStory;
  story: StoryblokStory;
};

export function ScientificUpdatePage({ data, location }: PageProps<PageData>) {
  const LazyNewsletter = lazy(() => import("../sections/Newsletter"));

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const { story, footer, header } = data;
  const parsedStory = useStoryblok<ScientificUpdatePageTemplate>(
    story,
    location
  );

  const {
    content: {
      su_categories,
      su_hero_img,
      su_date_of_publication,
      su_title,
      su_labels,
      su_content,
      su_cta,
      seo,
    },
    full_slug,
  } = parsedStory;

  const parsedHeader = JSON.parse(header.content) as HeaderTemplate;
  const parsedFooter = JSON.parse(footer.content) as FooterTemplate;
  const documentReport = su_cta?.find(
    (item) => item.component === "element_document"
  ) as DocumentElement;

  return (
    <MainLayout
      header={parsedHeader}
      footer={parsedFooter}
      seo={{ ...seo, og_image: seo?.og_image || su_hero_img?.image }}
      type="article"
    >
      <ScientificUpdateHero
        su_categories={su_categories}
        su_hero_img={su_hero_img}
        su_date_of_publication={su_date_of_publication}
        su_title={su_title}
        su_labels={su_labels}
      />
      <ScientificUpdateContent
        su_title={su_title}
        su_content={su_content}
        su_cta={su_cta}
        postUrl={full_slug}
        su_hero_img={su_hero_img}
      />

      {documentReport && (
        <DocumentReport
          title={documentReport.title}
          description={documentReport.description}
          buttonLabel={documentReport.download_label}
          label={documentReport.label}
          url={documentReport.file.filename}
        />
      )}

      <LazyIntersectionObserver
        Component={LazyNewsletter}
        inView={inView}
        ref={ref}
      />
    </MainLayout>
  );
}

export const pageQuery = graphql`
  query SuPageQuery($id: String!) {
    story: storyblokEntry(id: { eq: $id }) {
      id
      content
      full_slug
    }
    header: storyblokEntry(field_component: { eq: "template_header" }) {
      content
    }
    footer: storyblokEntry(field_component: { eq: "template_footer" }) {
      content
    }
  }
`;

export default ScientificUpdatePage;
